//https://medium.com/@bit101/flow-fields-part-i-3ebebc688fd8
import React, { useRef, useEffect } from 'react'

import {Body, Events } from 'matter-js'

import { useWindowSize } from "./../ballons/useWindowSize";

export function WindSimulator({ runner, ballons, onPoints }){

	const canvasRef = useRef(null);
	const isInit = useRef(false);
	const intervalIdRef = useRef();
	const windowSize = useWindowSize();

	// random attractor params
	var a = Math.random() * 4 - 2;
	var b = Math.random() * 4 - 2;
	var c = Math.random() * 4 - 2;
	var d = Math.random() * 4 - 2;
	const points = [];
	
	function init() {
		if (canvasRef.current) {
			const context = canvasRef.current.getContext('2d');
			context.clearRect(0, 0, windowSize.width, windowSize.height);
			context.lineWidth = 0.2;
		}

		a = Math.random() * 4 - 2;
		b = Math.random() * 4 - 2;
		c = Math.random() * 4 - 2;
		d = Math.random() * 4 - 2;

		points.length = 0;
		var step = 100;
		for(var x = 0; x < windowSize.width; x+=step) {
			for (var y = 0; y < windowSize.height; y+=step) {
				points.push({
				x: x,
				y: y, 
				vx: 0,
				vy: 0
			});
			}
		}
	}

	function callback() {
		// TODO We use this to reset the canvas every 5 seconds.
		// This could be achived with setInterval but I ran into
		// some hard bugs and this is the most straighforward solution
		// for now.
		if (((new Date()).getSeconds() % 5) == 0) {
			init();
		}

		// TODO figure out how to better do this cleanup
		if (!canvasRef.current) Events.off(runner, "afterTick");

    	updatePoints(canvasRef.current.getContext('2d'), getColor());
    		for (var i = 0; i < points.length; i++) {
			for (var j = 0; j < ballons.length; j++) {
				Body.applyForce(ballons[j], {x: points[i].x, y: points[i].y}, {x: points[i].vx, y: points[i].vy});
			}
        }
	}

	useEffect(() => {
		if (isInit.current) return;
		isInit.current = true;
	    const canvas = canvasRef.current
	    const context = canvas.getContext('2d')
	    context.lineWidth = 0.2;

	    init();

		Events.on(runner, "afterTick", callback);
		return () => {
			Events.off(runner, "afterTick");
		}
  	}, [])

  	useEffect(() => {
  		Events.off(runner, "afterTick");
  		Events.on(runner, "afterTick", callback);
  	}, [windowSize])

	function getValue(x, y) {
		// clifford attractor
		// http://paulbourke.net/fractals/clifford/

		// scale down x and y
		var scale = 0.005;
		x = (x - windowSize.width / 2) * scale;
		y = (y - windowSize.height / 2)  * scale;

		// attactor gives new x, y for old one. 
		var x1 = Math.sin(a * y) + c * Math.cos(a * x);
		var y1 = Math.sin(b * x) + d * Math.cos(b * y);

		// find angle from old to new. that's the value.
		return Math.atan2(y1 - y, x1 - x);
	}

	function getColor() {
		var v = Math.random();

		if (v > 0.75) return "red";
		if (v > 0.5) return "blue";
		if (v > 0.25) return "green";
		return "yellow";

	}

	function updatePoints(context, color) {
		for(var i = 0; i < points.length; i++) {
		    // get each point and do what we did before with a single point
		    var p = points[i];
		    var value = getValue(p.x, p.y);
		    p.vx += Math.cos(value) * 0.3;
		    p.vy += Math.sin(value) * 0.3;

		    // move to current position
		    if (context) {
		    	context.beginPath();
		    	context.moveTo(p.x, p.y);
		    }

		    // add velocity to position and line to new position
		    p.x += p.vx;
		    p.y += p.vy;

		    if (context) {
		    	context.strokeStyle = color;
		    	context.lineTo(p.x, p.y);
		    	context.stroke();
		    }

		    // apply some friction so point doesn't speed up too much
		    p.vx *= 0.000001;
		    p.vy *= 0.0000001;

		    // if (Math.random() > 0.999) console.log(p.vx, p.vy)

		    // wrap around edges of screen
		    if(p.x > windowSize.width) p.x = 0;
		    if(p.y > windowSize.height) p.y = 0;
		    if(p.x < 0) p.x = windowSize.width;
		    if(p.y < 0) p.y = windowSize.height;
		}

		return points;
	}

	return (
		<canvas ref={canvasRef} width={windowSize.width} height={windowSize.height} style={{position: "fixed"}}></canvas>
	);
}