
import { useEffect, useRef, useState } from 'react'
import { Engine, Runner, Render, Bodies, World, Constraint } from 'matter-js'


import { useWindowSize } from "./useWindowSize";
import { WindSimulator } from "./../wind/WindSimulator";

export function BallonsAnimation() {

    const windowSize = useWindowSize();
    const scene = useRef();
    const renderRef = useRef(null);
    const engine = Engine.create();
    const runner = useRef(Runner.create({ enabled: true}));
    const [ballons, setBallons] = useState([]);
    const [constraints, setConstraints] = useState([]);

    const isInit = useRef(false);

    // Init
    useEffect(() => {
        if (isInit.current) return;
        isInit.current = true;

        renderRef.current = Render.create({
                element: scene.current,
                engine: engine,
                options: {
                    width: windowSize.width,
                    height: windowSize.height,
                    wireframes: false,
                    background: 'transparent',
                    showAngleIndicator: false
                }
            });

        engine.gravity.y = -1;
        initBallons(3, 50, windowSize.width - 50, windowSize.height, 0, 200, 100);
        Runner.run(runner.current, engine);
        Render.run(renderRef.current);

    }, [])

    // on window resize
    useEffect(() => {
        for (var i = 0; i < constraints.length; i++) {
            constraints[i].pointA.x = windowSize.width - 50;
            constraints[i].pointA.y = windowSize.height;
        }

        if (renderRef.current == null) return;
        renderRef.current.bounds.max.x = windowSize.width;
        renderRef.current.bounds.max.y = windowSize.height;
        renderRef.current.options.width = windowSize.width;
        renderRef.current.options.height = windowSize.height;
        renderRef.current.canvas.width = windowSize.width;
        renderRef.current.canvas.height = windowSize.height;
        Render.setPixelRatio(renderRef.current, window.devicePixelRatio); // added this

    }, [windowSize])

    function initBallons(ballonCount, bSize, baseX, baseY, offsetX, offsetY, stringLength) {
        const ballonOpts = {
            isStatic: false,
            mass: 1,
            restitution: 0.01,
            friction: 0.1,
            frictionAir: 0.01,
            render: {
                strokeStyle: '#ffffff',
                sprite: {
                    texture: 'ballon.png',
                    xScale: bSize / 1200,
                    yScale: bSize / 1200
                }
            }    
        };

        var tmpBallons = [];
        var tmpConstraints = [];
        for (var i = 0; i < ballonCount; i++) {

            var sl = stringLength * (((0.5 * Math.random()) - 0.5) + 1)

            var ballon = Bodies.rectangle(baseX, baseY - sl, bSize, bSize * 2, ballonOpts);
            tmpBallons.push(ballon);

          var constraint = Constraint.create({
                bodyB: ballon,
                length: sl,
                pointB: {x: 0, y: bSize},
                pointA: { x: baseX, y: baseY },
                stiffness: 1,
                damping: 1,
                render: {
                    visible: true,
                    type: "line",
                    anchors: false,
                    strokeStyle: "#adadad"
                }
            });
          tmpConstraints.push(constraint);
        }
        setBallons(tmpBallons);
        setConstraints(tmpConstraints);

        World.add(engine.world, [
            ...tmpBallons, 
            ...tmpConstraints,
        ]);
    }

    return (
        <div
          style={{position: "fixed", height: "100%", width: "100%", top: "0"}}
        >
            <div ref={scene} width={windowSize.width} height={windowSize.height} style={{position: "fixed"}}></div>
            {ballons.length > 0 && (
                <WindSimulator runner={runner.current} ballons={ballons} />
            )}
        </div>
    );

}