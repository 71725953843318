
import "./CardFrame.css";

const CardFrame = ({ children }) => {

	return (
		<div className="cardFrame">
			{children}
		</div>
	);
}


export default CardFrame;