import CardFrame from "./../templates/CardFrame";

export default () => {
	return (
		<div className="container">
			<h2>Gift Guide</h2>
			<p>I am extremely lucky and privileged to have everything I could possibly need to grow up a happy and healthy baby and having fun with you is the best gift I could receive.</p>

			<p>If you still feel that you would like to give me a gift, here are some ideas of gifts I would love:</p>

			<div className="cardContainer cardContainer_gift">
				<a className="cardWrapper_gift card" href="https://www.libertyhumane.org/" target="_blank">
					<h3>Liberty Humane Society Donation 🐶🐱</h3>
					<p>I would love to help kitties and doggies of Jersey City.</p>
				</a>

				<a className="cardWrapper_gift card" href="https://www.gymboreeclasses.com/en/booker/gift_certificate/giftAddPayInfo.html" target="_blank">
					<h3>Gymboree Giftcard 🏋️</h3>
					<p>I love playing at the gym and making new friends.</p>
				</a>

				<a className="cardWrapper_gift card" href="https://britishswimschool.com/give-the-gift-of-life/" target="_blank">
					<h3>British Swimming School Giftcard 🏊‍♂️</h3>
					<p> being comfortable in the water is very important and I've been making great progress.</p>
				</a>

				<a className="cardWrapper_gift card" href="https://tickets.lsc.org/donate/contribute1?ct=17" target="_blank">
					<h3>Liberty Science Center Giftcard 🔭</h3>
					<p>We are a family of nerds and I'm excited to hang out and learn about the world.</p>
				</a>

				<a className="cardWrapper_gift card" href="https://www.target.com/c/cables-cords-adapters-computer-office-accessories-computers-electronics/white/-/N-5s7dqZ5y750" target="_blank">
					<h3>Toys: Cables 🔌</h3>
				</a>
			</div>
		</div>
	);
}