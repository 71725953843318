import './App.css';

import CardFrame from '../templates/CardFrame';
import {BallonsAnimation} from "../ballons/BallonsAnimation";

function AdultParty() {
  return (
    <>
      <CardFrame>
        <h1>
        Welcome to my first birthday party preparation page!
        </h1>

        <p><b>When</b></p>
        <p>Friday April 7th at 6pm - 10pm</p>

        <p><b>Where</b></p>
        <p>255 Warren St, Playroom in the Second Floor</p>
        <p>Jersey City, NJ, 07302</p>

        <h2>Please RSVP by February 28th by emailing us at andrango.juanandres @ gmail.com or texting/calling to 347 334 1180</h2>

        <img src="ruslan_living_room.jpg" width="600px"></img>

      </CardFrame>
    </>
  );
}

export default AdultParty;
