import { Link } from 'react-router-dom';

import CardFrame from "./../templates/CardFrame";

import "./App.css";


export default () => {
	return (
		<div className="container">
			<h1>Hello!</h1>
			<p>I'm <b>Ruslan Alexander</b> and I'll soon be 1️⃣🥳</p>

			<p>My parents are putting together a party 🎈🪩🎉 so you can celebrate with me!</p>

			<div className="cardContainer">
				<Link to="/first-bday-invitation" className="cardWrapper card" reloadDocument>
					<div>
						<h2>Children party!</h2>

						<p>Please bring your children to my birthday party on <b>April 8th!</b></p>

					 	<p>Click here to open the invite</p>

					 	<h1>✉️</h1>
					</div>
				</Link>

				<Link to="/adults-invitation" className="cardWrapper card">
					<div>
						<h2>Adults party!</h2>
						<p>My parents are also setting up a get together on <b>April 7th!</b> so you can celebrate me as well.</p>

						<p>Click here to open the invite</p>

						<h1>✉️</h1>
					</div>
				</Link>

				<Link to="/gift-guide" className="cardWrapper card">
					<div>
						<h2>Gift Guide!</h2>
						
						<h1>🎁</h1>
					</div>
				</Link>
			</div>
		</div>
		);
}