import './App.css';

import { HashRouter as Router, Routes, Route} from 'react-router-dom';

import FirstBdayInvitation from "./pages/FirstBdayInvitation";
import AdultParty from "./pages/AdultParty";
import Home from "./pages/Home";
import GiftGuide from "./pages/GiftGuide";

function App() {
  return (
        <Router>
          <Routes>
            <Route exact path='/' exact element={<Home />} />
            <Route path='/first-bday-invitation' element={<FirstBdayInvitation/>} />
            <Route path='/adults-invitation' element={<AdultParty/>} />
            <Route path='/gift-guide' element={<GiftGuide/>} />
          </Routes>
        </Router>

  );
}

export default App;
